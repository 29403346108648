import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SimplemdeModule } from 'ngx-simplemde';
import { NgxSpinnerModule } from "ngx-spinner";

// Firebase
import { environment } from '../environments/environment';
import { AuthService } from './core/auth.service';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MediaplayerComponent } from './components/mediaplayer/mediaplayer.component';
import { EncryptionComponent } from './components/encryption/encryption.component';
import { JsonfetchComponent } from './components/jsonfetch/jsonfetch.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { LoginComponent } from './components/login/login.component';
import { WriterComponent } from './components/writer/writer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudDriveComponent } from './components/cloud-drive/cloud-drive.component';

@NgModule({
  declarations: [
    AppComponent,
    MediaplayerComponent,
    EncryptionComponent,
    JsonfetchComponent,
    BookmarksComponent,
    LoginComponent,
    WriterComponent,
    CloudDriveComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    SimplemdeModule.forRoot({
      // Global options
      options: {
        autosave: { enabled: true, uniqueId: '1d876039-dd78-4d1f-8324-1bffe8923214' },
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule // for storage and file upload
  ],
  providers: [AuthService, AngularFireAuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
