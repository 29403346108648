<h3>CHEDRIVE</h3>
<div>File list</div>
<div class="file-uploader">
	<!-- <div>
    <label class="input-group-text" for="file">Choose File</label>
    <input type="file"
           id="file"
           (change)="handleFileInput($event.target.files)"/>
	</div> -->
	<div>
        <input class="input-group-text" type="file"
            id="file"
            size="60"
            (change)="handleFileInput($event.target.files)"/>
    </div>
    <div>Uploading... {{ uploadPercentage$ | async }}%</div>
</div>
