<!--The content below is only a placeholder and can be replaced.-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/">CHE.APPLICATIONS</a>
  <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="navbarNav">
    <ul class="navbar-nav">
      <!--<li class="nav-item">-->
      <!--  <a class="nav-link" routerLink="/" routerLinkActive="active">Home</a>-->
      <!--</li>-->
      <li class="nav-item">
        <a class="nav-link" routerLink="/writer" routerLinkActive="active">Writer</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/drive" routerLinkActive="active">Dirve</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/memo" routerLinkActive="active">Memo</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/encryption" routerLinkActive="active">Encryption</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/media-player" routerLinkActive="active">Media Player</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/json-fetcher" routerLinkActive="active">Json Fetcher</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="http://chengz.freeshell.org">80's</a>
      </li>
    </ul>
  </div>
</nav>
<div class="app-content">
  <div class="center-content">
      <router-outlet></router-outlet>
  </div>
</div>