import { Component, OnInit } from '@angular/core';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-encryption',
  templateUrl: './encryption.component.html',
  styleUrls: ['./encryption.component.scss']
})
export class EncryptionComponent implements OnInit {
  cleanText: String = void 0;
  password: String = void 0;
  result: String = void 0;

  constructor() {
    
  }

  ngOnInit() {
  }

  encrypt() {
    this.result = '';

    var encrypted = crypto.AES.encrypt(this.cleanText, this.password);

    this.result = encrypted;
    this.cleanText = '';
    this.password = '';
  }

  decrypt() {
    this.result = '';

    var decrypted = crypto.AES.decrypt(this.cleanText, this.password);

    this.result = decrypted.toString(crypto.enc.Utf8);
    this.cleanText = '';
    this.password = '';
  }

  clearTextarea() {
    this.cleanText = '';
    this.password = '';
    this.result = '';
  }
}
