<div id="encryption-page">
    <div class="encryption">
      <p class="title">String Encryptor</p>
      <form ng-form>
        <div class="form-group">
          <textarea class="form-control" id="paintext" rows="6" [(ngModel)]="cleanText" name="cleanText"></textarea>
        </div>
        <div class="form-group">
          <input class="form-control" autocomplete="username" type="text" [hidden]="true">
          <input class="form-control" autocomplete="new-password" type="password" placeholder="Password" [(ngModel)]="password" id="password" name="password">
        </div>
        <div class="form-group">
          <input class="form-control btn btn-success btn-block" type="submit" value="Encrypt" (click)="encrypt()">
        </div>
        <div class="form-group">
          <input class="form-control btn btn-danger btn-block" type="submit" value="Decrypt" (click)="decrypt()">
        </div>
        <div class="form-group">
          <textarea class="form-control" id="ciphertext" rows="6" [(ngModel)]="result" name="result"></textarea>
        </div>
        <div class="form-group">
          <input class="form-control" type="button" value="Clear" (click)="clearTextarea()" id="clrear">
        </div>
      </form>
    </div>
  </div>
