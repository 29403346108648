import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo  } from '@angular/fire/auth-guard';

import { MediaplayerComponent } from './components/mediaplayer/mediaplayer.component';
import { EncryptionComponent } from './components/encryption/encryption.component';
import { JsonfetchComponent } from './components/jsonfetch/jsonfetch.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { LoginComponent } from './components/login/login.component';
import { WriterComponent } from './components/writer/writer.component';
import { CloudDriveComponent } from './components/cloud-drive/cloud-drive.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToBookmark = () => redirectLoggedInTo(['bookmark']);

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToBookmark }
  },
  {
    path: "writer",
    component: WriterComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  { 
    path: 'bookmark', 
    component: BookmarksComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  { 
    path: 'media-player', 
    component: MediaplayerComponent
  },
  { 
    path: 'json-fetcher', 
    component: JsonfetchComponent
  },
  { 
    path: 'encryption', 
    component: EncryptionComponent
  },
  { 
    path: 'drive', 
    component: CloudDriveComponent
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
