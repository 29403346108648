import { Component, OnInit, ViewChild, ElementRef, OnDestroy  } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-mediaplayer',
  templateUrl: './mediaplayer.component.html',
  styleUrls: ['./mediaplayer.component.scss']
})
export class MediaplayerComponent implements OnInit, OnDestroy {
  player: videojs.Player;
  // options = {
  //   controls: true,
  //   autoplay: false,
  //   muted: true,
  //   height: 300,
  //   width: 533,
  //   preload: 'auto',
  //   techOrder: ['html5']
  // };
  // URL do video a ser reproduzido.
  video: string;
  // Acess element video do html5 via viewchild.
  // https://blog.angular-university.io/angular-viewchild/
  // @ViewChild('target', {static: true}) target: ElementRef;
  
  constructor() {
    this.video = void 0;
  }

  ngOnInit() { }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  AfterViewInit() {
    // instantiate Video.js
    // this.player = videojs(this.target.nativeElement, null, function onPlayerReady() {
    //   console.log('onPlayerReady', this);
    // });
  }
}
