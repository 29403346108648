import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';

/*
The auth service is where most of the magic happens. It facilitates the sign-in process, watches the user session, and allows us to save custom user data to the Firestore database. Here’s a breakdown of everything that is happening in the code.

    interface User: The interface declares the properties of the custom user object. Feel free to add any custom data you want here to extend the basic Firebase auth data.
    constructor(): The constructor will set the Observable. First it receives the current Firebase auth state. If present, it will hit up Firestore for the user’s saved custom data. If null, it will return an Observable.of(null).
    googleLogin(): This method triggers the popup window that authenticates the user with their Google account. It returns a Promise that resolves with the auth credential. The oAuthLogin() method is useful if you have multiple OAuth options because it can be reused with different providers.
    updateUserData(): This private method runs after the user authenticates and sets their information to the Firestore database. We pass the { merge: true } option to make this a non-destructive set.

*/

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}


@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
      //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {
            return of(null)
          }
        })
      )
  }

  async login(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.router.navigate(['bookmark']);
    } catch (e) {
      console.log(e.message);
    }
  }

  googleLogin() {
    const provider = new auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      })
  }

  private updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    }

    return userRef.set(data, { merge: true })
  }

  signOut() {
    this.afAuth.signOut().then(() => {
        this.router.navigate(['/']);
    });
  }
}