import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as crypto from 'crypto-js';
import { AngularFirestore } from '@angular/fire/firestore';
import { Bookmark, Todo, Note } from '../interfaces/collection.interface';
import { CollectionName } from '../constants/collections';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    private db: AngularFirestore, 
    private httpClient: HttpClient) { }

  getFsColloction(cname: string){
    //We use snapshotChanges() and not valueChanges() because we will need to have 
    //the Users IDs available to then perform the Update and Delete operations.
    return this.db.collection(`/${cname}`).snapshotChanges().pipe(
      map(list => {
        return list.map(item => {
          const id = item.payload.doc.id;
          let data;
          switch(cname){
            case CollectionName.BOOKMARKS:
              data = item.payload.doc.data() as Bookmark;
              break;
            case CollectionName.NOTES:
              data = item.payload.doc.data() as Note;
              break;
            case CollectionName.TODOS:
              data = item.payload.doc.data() as Todo;
              break;
            defult:
              break;
          }
          return {id, ...data};
        })
      }),
      map(things => things.sort(this.compareFn))
    );
  }

  createFsCollection(collection: string, object: Object) {
    return this.db.collection(collection).add({...object});
  }

  updateFsCollection(collection: string, id: string, object: Object) {
    return this.db.collection(collection).doc(id).set(object);
  }

  deleteFsCollectionById(collection: string, id: string) {
    return this.db.collection(collection).doc(id).delete();
  }

  getJSON(url:string) {
    return this.httpClient.get(url);
  }

  getGist(url:string, passcode:string): Observable<any> {
    return this.httpClient.get(url, this.getHeaders(passcode))
    .pipe(
      map((resp: HttpResponse<any>) => resp['files'])
    );
  }

  patchGist(patchFile: string, url:string, content, passcode:string) {
    const structuredContent = {
      date: new Date(),
      data: content
    }
    const payload = {
      description: "Bookmarks",
      files: {
        patchFile: {
          "filename": patchFile,
          "content": JSON.stringify(structuredContent)
        }
      }
    }

    return this.httpClient.patch(url, payload, this.getHeaders(passcode));
  }

  /*
    POST - https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/notes
    GET - https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/notes/{id}
    GET - https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/notes
    PUT - https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/notes/{id}
    DELETE - https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/notes/{id}
  */
  awsGetNotes(passcode:string): Observable<any> {
    const awsurl = 'https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/note';
    return this.httpClient.get(awsurl, this.getAWSHeaders(passcode))
      .pipe(
        map((resp: HttpResponse<any>) => resp)
      );
  }

  awsUpdateNotes(id: String, body: String, passcode:string): Observable<any> {
    const awsurl = `https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/note/${id}`;
    return this.httpClient.put(awsurl, body, this.getAWSHeaders(passcode))
      .pipe(
        map((resp: HttpResponse<any>) => resp)
      );
  }

  awsAddNote(body: String, passcode:string): Observable<any> {
    const awsurl = 'https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/note';
    return this.httpClient.post(awsurl, body, this.getAWSHeaders(passcode))
      .pipe(
        map((resp: HttpResponse<any>) => resp)
      );
  }

  awsDeleteNote(id: String, passcode:string): Observable<any> {
    const awsurl = `https://vshb53js6h.execute-api.us-east-1.amazonaws.com/dev/note/${id}`;
    return this.httpClient.delete(awsurl, this.getAWSHeaders(passcode))
      .pipe(
        map((resp: HttpResponse<any>) => resp)
      );
  }

  private getHeaders(passcode: string) {
    const encoded = 'U2FsdGVkX19NvA7TMWNIYZ8JmfmsE2ZHOp3NbfBY68AH95uhDU4g5dRqrelCRrIUMVp3PsjKVLeKOZribMWOLQ==';
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `token ${this.decryptToken(passcode, encoded)}`
      })
    };
  }

  private getAWSHeaders(passcode: string) {
    return {
      headers: new HttpHeaders({
        'app-token': this.decryptToken(passcode)
      })
    };
  }

  private decryptToken(passcode:string, encoded:string = 'U2FsdGVkX1+8w5+OoFnMyjuNRT0u+Dg02Qk/1x2LCUMgUY07u1VbColbH/1NPBeF') {
    const decrypted = crypto.AES.decrypt(encoded, passcode);

    return decrypted.toString(crypto.enc.Utf8);
  }

  private compareFn(a, b) {
    if (a.timestamp < b.timestamp)
      return 1;
    if (a.timestamp > b.timestamp)
      return -1;
    return 0;
  }
}
