import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Bookmark } from '../../interfaces/collection.interface';
import { ResourceService } from '../../services/resource.service';

import { CollectionName } from '../../constants/collections';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
    public bookmarks$: Observable<any[]>;
    public todos$: Observable<any[]>;

    bookmark: Bookmark = { name: '', url: ''};
    toggleBookmarkDelete = Number.MAX_SAFE_INTEGER;
    toggleTodoDelete = Number.MAX_SAFE_INTEGER;
    
    constructor(
        private service: ResourceService,
        private spinner: NgxSpinnerService) { 
            this.loadBookmarks();
            this.loadTodos();
        }

    ngOnInit() { }

    loadBookmarks () {
        this.spinner.show();
        this.bookmarks$ = this.service.getFsColloction(CollectionName.BOOKMARKS);
        this.spinner.hide();
    }

    loadTodos () {
        this.spinner.show();
        this.todos$ = this.service.getFsColloction(CollectionName.TODOS);
        this.spinner.hide();
    }

    addBookmark () {
        this.spinner.show();
        this.service.createFsCollection(CollectionName.BOOKMARKS, this.bookmark)
            .then(res => {
                this.resetBookmarkInputs();
            })
        this.spinner.hide();
    }

    addTodo (event) {
        this.spinner.show();
        this.service.createFsCollection(CollectionName.TODOS, {content: event.target.value, timestamp: Date.now()})
            .then(res => {
                event.target.value = '';
            })
        this.spinner.hide();
    }

    deleteBookmark (bookmark: Bookmark) {
        this.spinner.show();
        this.service.deleteFsCollectionById(CollectionName.BOOKMARKS, bookmark.id)
            .then(res => this.cancelRowDeletion());
        this.spinner.hide();
    }

    cancelRowDeletion () {
        this.toggleBookmarkDelete = Number.MAX_SAFE_INTEGER;
        this.toggleTodoDelete = Number.MAX_SAFE_INTEGER;
    }

    deleteTodo (todo) {
        this.spinner.show();
        this.service.deleteFsCollectionById(CollectionName.TODOS, todo.id)
        .then(res => this.cancelRowDeletion());
        this.spinner.hide();
    }

    search (event) {
        this.bookmarks$ = this.bookmarks$.pipe(
            debounceTime(200),
            map( bookmarks => bookmarks.filter(bookmark => event.target.value ? bookmark['name'].toLowerCase().includes(event.target.value.toLowerCase()) : true))
        )
    }

    resetBookmarkInputs() {
        this.bookmark = { name: '', url: ''};
    }

}
