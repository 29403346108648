import { Component, OnInit } from '@angular/core';
import { ResourceService } from '../../services/resource.service';

@Component({
  selector: 'app-jsonfetch',
  templateUrl: './jsonfetch.component.html',
  styleUrls: ['./jsonfetch.component.scss']
})
export class JsonfetchComponent implements OnInit {
  serverUrl:string;
  movies:any;

  constructor(private resource: ResourceService) {
    this.serverUrl = '';
    this.movies = void 0;
  }

  ngOnInit() {
  }

  getJSON() {
    this.resource.getJSON(this.serverUrl)
      .subscribe(movies => this.movies = JSON.stringify(movies, null, 2));
  }

  // prettifyJSON(json: any){
  //     if (typeof json != 'string') {
  //       json = JSON.parse(json);
  //     }
  //     const files = json.files;
  //     let dom = '<div>';
  //     files.array.forEach(element => {
  //       dom = `${dom}<div>#${element}</div><br>`;
  //     });
  //     dom = `${dom}</div>`
  // }

}
