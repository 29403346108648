import { Component, OnInit, DoCheck } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResourceService } from '../../services/resource.service';
import { Observable } from 'rxjs';
import { CollectionName } from '../../constants/collections';
import { Note } from '../../interfaces/collection.interface';
import { debounceTime, map } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-writer',
  templateUrl: './writer.component.html',
  styleUrls: ['./writer.component.scss']
})
export class WriterComponent implements OnInit, DoCheck {
  notes$: Observable<any[]>;
  editingNoteContent = '';
  originalNoteContent = '';
  editingNoteId = void 0;
  selectedNumber = Number.MAX_SAFE_INTEGER;
  confirmDeletion = false;
  unsaved: boolean = false;

  constructor(
      private resourceService: ResourceService,
      private spinner: NgxSpinnerService,
      private modalService: NgbModal
  ) { }

  ngOnInit() { 
    this.loadNotes();
  }

  ngDoCheck() {
    if(this.editingNoteContent !== this.originalNoteContent && !this.unsaved){
      console.log('content changed');
      this.unsaved = true;
    }else if(this.editingNoteContent === this.originalNoteContent && this.unsaved){
      console.log('content is same');
      this.unsaved = false;
    }
  }

  getTitle (content: string) {
    return content.split("\n")[0];
    //return content.slice(0,10);
  }

  openAndEdit (note: Note, content) {
    this.editNote(note);
    this.openModal(content);
  }

  editNote (note: Note) {
    this.editingNoteId = note.id;
    this.editingNoteContent = note.content;
    this.originalNoteContent = this.deepClone(note.content);
  }

  cancelUpdateNote () {
    this.editingNoteContent = '';
    this.originalNoteContent = '';
    this.unsaved = false;
    this.editingNoteId = void 0;
    this.confirmDeletion = false;
  }

  loadNotes () {
    this.spinner.show();
    this.notes$ = this.resourceService.getFsColloction(CollectionName.NOTES);
    this.spinner.hide();
  }

  addNote () {
    this.spinner.show();
    this.resourceService.createFsCollection(CollectionName.NOTES, {content: this.editingNoteContent, timestamp: Date.now()})
      .then(res => {
        this.editingNoteContent = ''; 
        this.spinner.hide();
      });
  }

  updateNote () {
    this.spinner.show();
    this.resourceService.updateFsCollection(CollectionName.NOTES, this.editingNoteId, {content: this.editingNoteContent, timestamp: Date.now()})
      .then(res => {
        this.spinner.hide();
        this.originalNoteContent = this.deepClone(this.editingNoteContent);
    });
  }
  
  deleteNote () {
    this.spinner.show();
    this.resourceService.deleteFsCollectionById(CollectionName.NOTES, this.editingNoteId)
      .then(res => {
        this.spinner.hide();
        this.cancelUpdateNote();

      });
  }
  
  toggleDeleteFlag () {
    this.confirmDeletion = !this.confirmDeletion;
  }

  search (event) {
    this.notes$ = this.notes$.pipe(
      debounceTime(200),
      map( notes => notes.filter(note => event.target.value ? note['content'].toLowerCase().includes(event.target.value.toLowerCase()) : true))
    )
  }

  openModal(content) {
    this.modalService.open(content, 
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        centered: true,
        scrollable: true
      }).result.then((result) => {
        this.cancelUpdateNote();
      }, (reason) => {
        this.cancelUpdateNote();
      });
  }

  private deepClone (obj) {
    return JSON.parse(JSON.stringify(obj));
  }

}
