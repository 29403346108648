<div class="login-form">
    <form class="inner" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">Username</label>
            <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                <div *ngIf="f['username'].errors.required">Username is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password'].errors.required">Password is required</div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-primary">Login</button>
            <a [routerLink]="['/register']" class="btn btn-link">Register</a>
        </div>
    </form>
</div>
