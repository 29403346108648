<ngx-spinner></ngx-spinner>
<div class="row home">
    <div class="col-md-6 bookmark">
        <div class="search input-group mb-3" *ngIf="bookmarks$">
            <input type="text" class="form-control" placeholder="Search" (keyup)="search($event)">
        </div>
        <div class="search-result">
            <ul class="list-group">
                <li class="list-group-add">
                    <form (ngSubmit)="addBookmark()" #bookmarkForm="ngForm">
                        <div class="input-group mb-3 form-group">
                            <input type="text" 
                                class="form-control" 
                                placeholder="Description" 
                                aria-label="name" 
                                aria-describedby="basic-addon2" 
                                [(ngModel)]="bookmark.name"
                                name="name"
                                #name="ngModel">
                            <input type="text" 
                                class="form-control" 
                                placeholder="URL address" 
                                aria-label="url" 
                                aria-describedby="basic-addon2" 
                                [(ngModel)]="bookmark.url"
                                name="url"
                                #url="ngModel">
                            <div class="input-group-append">
                                <button class="btn btn-success" type="submit">Add</button>
                            </div>
                        </div>
                    </form>
                </li>
                <li class="list-group-item item-list"
                    *ngFor="let bookmark of bookmarks$ | async; let i = index">
                    <div *ngIf="toggleBookmarkDelete !== i">
                        <a target="_blank"
                            href="{{bookmark.url}}">{{ bookmark.name }}
                        </a>
                        <button type="button" class="btn btn-warning delete-btn" (click)="toggleBookmarkDelete = i">Delete</button>
                    </div>
                    <div *ngIf="toggleBookmarkDelete === i">
                        <button type="button" class="btn btn-danger delete-btn" (click)="deleteBookmark(bookmark)">Confirm</button>
                        <button type="button" class="btn btn-secondary delete-btn" (click)="cancelRowDeletion()">Cancel</button>
                    </div>
                </li>
            </ul>
        </div>
    
    </div>
    <div class="col-md-6 todo">
        <div class="search input-group mb-3">
            <input type="text" class="form-control" placeholder="Add todos" (keyup.enter)="addTodo($event)">
        </div>
        <div class="search-result">
            <ul class="list-group">
                <li class="list-group-item"
                    *ngFor="let todo of todos$ | async, let i = index">
                    {{ todo.content }}
                    <button *ngIf="toggleTodoDelete !== i" type="button" class="btn btn-warning delete-btn" (click)="toggleTodoDelete = i">Done</button>
                    <button *ngIf="toggleTodoDelete === i" type="button" class="btn btn-danger delete-btn" (click)="deleteTodo(todo)">Confirm</button>
                    <button *ngIf="toggleTodoDelete === i" type="button" class="btn btn-secondary delete-btn" (click)="cancelRowDeletion()">Cancel</button>
                </li>
            </ul>
        </div>
    </div>
</div>
