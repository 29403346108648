import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

//https://github.com/angular/angularfire/blob/master/docs/storage/storage.md
@Component({
  selector: 'app-cloud-drive',
  templateUrl: './cloud-drive.component.html',
  styleUrls: ['./cloud-drive.component.scss']
})
export class CloudDriveComponent implements OnInit {
  uploadPercentage$: Observable<number>;
  downloadURL$: Observable<string>;
  filePath: string = '';

  constructor(private storage: AngularFireStorage, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.auth.authState.subscribe(user => {
      this.filePath = user.uid;
    }); 
  }

  handleFileInput(files: File[]) {
    const file = files[0];
    const fileRef = this.storage.ref(`${this.filePath}/${file.name}`);
    //const task = ref.put(file);
    const task = this.storage.upload(`${this.filePath}/${file.name}`, file);

    // observe percentage changes
    this.uploadPercentage$ = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
        finalize(() => this.downloadURL$ = fileRef.getDownloadURL() )
     )
    .subscribe()
  }
}
