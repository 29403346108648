<ngx-spinner></ngx-spinner>
<div class="row notes justify-content-md-center">
    <div class="col-md-6 col-md-auto notelist">
        <div class="search input-group mb-3" *ngIf="notes$">
            <input type="text" class="form-control" placeholder="Search" (keyup)="search($event)">
            <button class="btn btn-primary" (click)="openModal(content)">Add New</button>
        </div>
        <div class="search-result">
            <ul class="list-group">
                <li class="list-group-item item-list"
                    *ngFor="let note of notes$ | async; let i = index"
                    (click) = "openAndEdit(note, content)">
                    {{getTitle(note.content)}}
                </li>
            </ul>
        </div>
    </div>
</div>

<ng-template #content let-modal class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Note</h4>
    <span class="badge badge-success" *ngIf="!unsaved">Good</span>
    <span class="badge badge-danger" *ngIf="unsaved">Pending</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <simplemde class="edit-area" [(ngModel)]="editingNoteContent" name="edit-note-form"></simplemde>
  </div>
  <div class="modal-footer">
    <div *ngIf="!confirmDeletion" class="form-group button-group">
      <button *ngIf="!editingNoteId" type="button" class="btn btn-warning" (click)="addNote()">Add New</button>
      <button *ngIf="editingNoteId" [attr.disabled]="editingNoteContent && unsaved ? null : true" (click)="updateNote()" type="button" class="btn btn-danger">Update</button>
      <button *ngIf="selectedNumber >= 0" [attr.disabled]="selectedNumber >= 0 && editingNoteContent ? null : true" (click)="toggleDeleteFlag()" type="button" class="btn btn-danger">Delete</button>
      <!-- <button [attr.disabled]="editingNoteContent ? null : true" (click)="cancelUpdateNote()" type="button" class="btn btn-secondary">Cancel</button> -->
    </div>
    <div *ngIf="confirmDeletion" class="form-group button-group">
      <button type="button" class="btn btn-danger" (click)="deleteNote()">Confirm</button>
      <button type="button" class="btn btn-secondary" (click)="toggleDeleteFlag()">Cancel</button>
    </div>
  </div>
</ng-template>
