<div class="app-content">
  <div class="media-player">
    <div class="keypass input-group">
      <input type="text" class="form-control" [(ngModel)]="video" placeholder="media url" aria-label="Media's URL" aria-describedby="basic-addon2">
    </div>
    <div class="row" *ngIf="video">
      <div class="col text-center">
        <video #target class="video-js vjs-big-play-centered"
                controls muted="true" height="300" width="533" preload="auto">
          <source [src]="video" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</div>